<template>
    <div>
        <el-card style="margin-top: 10px">
            <div class="f20 fwb tac mb-2 mt-2">推荐结果</div>
            <div class="list-box uf uf fwrap-w tal" v-loading="loading">
                <template v-if="serviceList.length > 0">
<!--                    <vue-slick-carousel -->
<!--                    v-bind="settings">-->
                        <service-card v-for="item in serviceList" :service="item" @showDetail="chooseProduct(item)" :key="item.id"/>
<!--                    </vue-slick-carousel>-->
                </template>
                <template v-else>
                    <div class="tac wi100" style="height: 300px">
                        <icon-svg class="mt-3" style="font-size: 200px" name="wushuju"></icon-svg>
                        <div class="f14" style="color: #909399">暂无数据</div>
                    </div>
                </template>
            </div>
        </el-card>
    </div>
</template>

<script>
    // import VueSlickCarousel from 'vue-slick-carousel'
    import ServiceCard from './service-card'
    export default {
        name: 'recommend-result-word2vec',
        components: {
            ServiceCard
            // VueSlickCarousel
        },
        computed: {},
        data() {
            return {
                enterpriseId: '',
                loading: true,
                serviceList: [],
                settings: {
                    dots: true,
                    focusOnSelect: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    touchThreshold: 5
                }
            }
        },
        mounted() {
        },
        created() {
        },
        methods: {
            //质量安全监测报告
            init(enterpriseId) {
                this.enterpriseId = enterpriseId
                this.$http({
                    url: this.$http.adornUrl(this.$api.PUB.RECOMMEND_PRODUCT),
                    method: 'POST',
                    params: this.$http.adornParams({
                        enterpriseId: this.enterpriseId
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        let patentList = data.patent || []
                        let a = []
                        if(patentList.length > 0){
                            a = patentList
                            this.serviceList = a
                        }
                        let tmList = data.tm || []
                        let b = []
                        if(tmList.length > 0){
                           b = this.serviceList.concat(tmList)
                            this.serviceList = b
                        }
                        let softList = data.soft || []
                        let c = []
                        if(softList.length > 0){
                            c = this.serviceList.concat(softList)
                            this.serviceList = c
                        }
                        let works = data.works || []
                        let d = []
                        if(works.length > 0){
                            d = this.serviceList.concat(works)
                            this.serviceList = d
                        }
                        let credit = data.credit || []
                        let e = []
                        if(credit.length > 0){
                            e = this.serviceList.concat(credit)
                            this.serviceList = e
                        }
                        let yuqing = data.yuqing || []
                        let f = []
                        if(yuqing.length > 0){
                            f = this.serviceList.concat(yuqing)
                            this.serviceList = f
                        }
                        let tech = data.tech || []
                        let g = []
                        if(tech.length > 0){
                            g = this.serviceList.concat(tech)
                            this.serviceList = g
                        }
                        let gaoxin = data.gaoxin || []
                        let h = []
                        if(gaoxin.length > 0){
                            h = this.serviceList.concat(gaoxin)
                            this.serviceList = h
                        }
                        let zhuanjing = data.zhuanjing || []
                        let k = []
                        if(yuqing.length > 0){
                            k = this.serviceList.concat(zhuanjing)
                            this.serviceList = k
                        }
                        let n = this.serviceList.length / 5
                        let result = []
                        result.push(this.serviceList[0])
                        result.push(this.serviceList[1*n])
                        result.push(this.serviceList[2*n])
                        result.push(this.serviceList[3*n])
                        result.push(this.serviceList[4*n])
                        this.serviceList = result
                        let view = data.view || []
                        let j = []
                        j = this.serviceList.concat(view)
                        this.serviceList = j
                        this.loading = false
                    }
                })
            },
            // 选择产品
            chooseProduct (item) {
                let itemCodeType = 'recommend'
                this.$router.push({name: 'service', query: {id: item.id,itemCodeType:itemCodeType}})
            }
        }
    }
</script>
<style scoped>
</style>
